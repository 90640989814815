<template>
  <div class="sso-container">
    <div class="loading-content" v-if="!$store.state.token">
      <div class="loading-spinner">
        <svg class="circular" viewBox="25 25 50 50">
          <circle class="path" cx="50" cy="50" r="20" fill="none" />
        </svg>
      </div>
      <p class="loading-text">身份验证中</p>
      <div class="loading-dots">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
import { SetPvAndUv } from '@/mixins/PvAndUv'
import { api } from '@/api/index'

import service from '@api/service'
import config from '@/config'
import common from '@/utils/common'

console.log('--------------------------', { service })

export default {
  name: 'Index',
  mixins: [SetPvAndUv],
  data() {
    return {
      ticket: '',
      ticketId: '', //微信环境
      userId: '',
      userName: '',
      token: '',
      userType: '',
      sp: '',
    }
  },
  mounted() {
    this.handleUrl().then(() => {
      // common.DialogStr('有回调访问')
      console.log('回调访问')
    }).catch(() => {
      // common.DialogStr('没有回调访问')
      console.log('没有回调访问')
    })
    // 判断环境微信
    if (!this.judgeEnvWechat()) {
      // ZWJSBridge.showPreloader({
      //   text: '请稍等..',
      //   showIcon: true,
      // })
    }
    //二次回退
    // 判断环境支付宝
    if (this.judgeEnv()) {
      if (this.$store.state.token) {
        this.$store.state.token = ''
        my.navigateBack()
      }
    } else {
      if (this.$store.state.token) {
        this.$store.state.token = ''
        ZWJSBridge.close()
      }
    }
    if (this.judgeEnvWechat()) {
      this.loginWx() // Login process for WeChat environment
    } else {
      this.loginZlb() // Login process for other environments
    }
  },
  methods: {
    // 浙里办登录
    loginZlb() {
      this.ticket = this.getQuery('ticket') ? this.getQuery('ticket') : ''
      console.log('ticket', this.ticket)
      this.sp = this.getQuery('sp') ? this.getQuery('sp') : '' //扫码跳转的地址
      this.token = this.getQuery('token') ? this.getQuery('token') : ''
      console.log('token', this.token)
      //兼容zfb zfb有时会调用不了ZWJSBridge
      if (this.judgeEnv()) {
        //个人登录
        if (this.ticket) {
          this.$store.dispatch('changeZlbUserType', 1).then((res) => {
            //个人单点登录成功
            this.getTokenByPersonal()
          })
        } else {
          window.location.replace(
            'https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959&redirectUrl=' + config.homeUrl,
          )
        }
      } else {
        ZWJSBridge.getUserType()
          .then((result) => {
            console.log('result', result)
            this.$store
              .dispatch('changeZlbUserType', result.userType)
              .then((res) => {
                if (result.userType == 2) {
                  if (!this.token) {
                    window.location.replace(
                      'https://esso.zjzwfw.gov.cn/opensso/spsaehandler/metaAlias/sp?spappurl=https://zyt.xcb.zj.gov.cn:7000/zyt/zlb/mobile/legalPersonSingleSignOn',
                    )
                  } else {
                    //法人单点登录成功
                    this.userId = this.getQuery('userid')
                    this.userName = this.getQuery('userName')
                    this.userType = this.getQuery('userType')
                    this.$store.commit('changeToken', this.token)
                    this.$store.commit('changeZlbUserId', this.userId)
                    this.$store.commit('changeUserName', this.userName)
                    this.$store.commit('changeUserType', this.userType)

                    api
                      .addUniqueVisitor({
                        dataSource: 1,
                        requestSource: 1,
                      })
                      .then((res) => {
                      })
                    this.getLegalPersonByUserId()
                  }
                } else {
                  if (!this.ticket) {
                    if (this.judgeEnv()) {
                      window.location.replace(
                        'https://puser.zjzwfw.gov.cn/sso/alipay.do?action=ssoLogin&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959',
                      )
                    } else {
                      window.location.replace(
                        `https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959&redirectUrl=${config.homeUrl}&togo=/login`,
                        // 'https://puser.zjzwfw.gov.cn/sso/mobile.do?action=oauth&scope=1&servicecode=BCDSGA_db0988b5e8dc97700c8205be915fe959&redirectUrl=https://zytmobile.dev.zhijiangfilm.com/',
                      )
                    }
                  } else {
                    //个人单点登录成功
                    this.getTokenByPersonal()
                  }
                }
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    },
    // 微信登录
    loginWx() {
      this.ticketId = this.getQuery('ticketId') ? this.getQuery('ticketId') : ''
      if (ZWJSBridge.ssoTicket) {
        ZWJSBridge.ssoTicket({}).then((ssoFlag) => {
          if (ssoFlag && ssoFlag.result === true) {
            //使用 IRS“浙里办”单点登录组件
            if (ssoFlag.ticketId) {
              //个人获取token
              this.getTokenByPersonalWX()
            } else {
              //当“浙里办”单点登录失败或登录态失效时调用 ZWJSBridge.openLink 方法重新获取 ticketId。
              ZWJSBridge.openLink({ type: 'reload' }).then((res) => {
                res.ticketId
              })
            }
          } else {
            //个人登录
            this.loginZlb()
          }
        })
      } else {
        //法人登录
        this.loginZlb()
      }
    },
    //判断app还是小程序
    judgeEnv() {
      const sUserAgent = window.navigator.userAgent.toLowerCase()
      const bIsAlipayMini =
        sUserAgent.indexOf('miniprogram') > -1 &&
        sUserAgent.indexOf('alipay') > -1
      if (bIsAlipayMini) {
        console.log('支付宝小程序')
        return true
      } else {
        console.log('app')
        return false
      }
    },
    //判断是否是微信环境
    judgeEnvWechat() {
      const isWechat =
        window.navigator.userAgent.toLowerCase().includes('miniprogram/wx') ||
        window.__wxjs_environment === 'miniprogram'
      if (isWechat) {
        return true
      } else {
        return false
      }
    },
    //获取地址上的参数
    // Method to retrieve query parameters from the URL
    getQuery(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = window.location.search.substr(1).match(reg)
      if (r != null) return unescape(r[2])
      return null
    },
    //个人获取token
    getTokenByPersonal() {
      // service({
      //   apiUrl: '/zlb/mobile/singleSignOn',
      //   url: 'mgop.zhijiang.zytv3.zlb0mobile0singleSignOn0',
      //   method: 'GET',
      //   data: {
      //     ticket: this.ticket,
      //   },
      // })
      // this.$axios({
      //   apiUrl: '/zlb/mobile/singleSignOn',
      //   url: 'mgop.zhijiang.zytv3.zlb0mobile0singleSignOn0',
      //   method: 'GET',
      //   data: {
      //     ticket: this.ticket,
      //   },
      // })
      api.singleSignOn({
        ticket: this.ticket,
      })
        .then((res) => {
          const { code, result, message } = res

          if (res.code == 200) {
            console.log('个人token成功' + JSON.stringify(result))
            this.$store.commit('changeToken', result.token)
            this.$store.commit('changeZlbUserId', result.userid)
            this.$store.commit('changeUserType', result.userType)
            this.$store.commit('changeUserName', result.username)
            this.$store.commit('changeUserInfo', result)
            api.addUniqueVisitor()
            ZWJSBridge.hidePreloader()
            this.handleRedirectUrl().then((res) => {
              console.log('回调地址', res)
            }).catch((error) => {
              this.$router.push({
                path: this.sp ? '/' + this.sp : '/home',
              })
            })

            sensors_sw.login(result.userid || '888888888888')
          } else if (code == 500) {
            ZWJSBridge.hidePreloader()
            Toast(message)
          }
        })
    },
    //个人获取token微信环境
    // Method to acquire token for individual users in WeChat environment
    getTokenByPersonalWX() {
      this.$axios({
        apiUrl: '/zlb/wechat/login',
        url: 'mgop.zhijiang.zytv3.zlb0wechat0login',
        method: 'GET',
        data: {
          ticketId: this.ticketId,
        },
      }).then((res) => {
        if (res.code == 200) {
          console.log('wx个人token成功' + JSON.stringify(res))
          this.$store.commit('changeToken', res.result.token)
          this.$store.commit('changeZlbUserId', res.result.userid)
          this.$store.commit('changeUserType', res.result.userType)
          this.$store.commit('changeUserName', res.result.username)
          api
            .addUniqueVisitor({
              dataSource: 1,
              requestSource: 1,
            })
            .then((res) => {
            })
          ZWJSBridge.hidePreloader()
          this.handleRedirectUrl().then((res) => {
            console.log('回调地址', res)
          }).catch((error) => {
            this.$router.push({
              path: this.sp ? '/' + this.sp : '/home',
            })
          })
        } else {
          Toast('身份验证失败,请登录个人用户账号')
          setTimeout(() => {
            ZWJSBridge.close()
          }, 2000)
        }
      })
    },
    //获取法人信息
    // Method to get information for legal persons
    getLegalPersonByUserId() {
      this.$axios({
        apiUrl: '/zlb/getZlbUserInfo',
        url: 'mgop.zhijiang.zytv3.zlb0getZlbUserInfo',
        method: 'GET',
        data: {
          userid: this.userId,
        },
      }).then((res) => {
        res.result.mobile = res.result.username //解决法人用户无手机号，后台用户名即为
        console.log('法人信息' + JSON.stringify(res))
        ZWJSBridge.hidePreloader()
        this.$store.dispatch('changeUserInfo', res.result).then((res) => {
          this.handleRedirectUrl().then((res) => {
            console.log('回调地址', res)
          }).catch((error) => {
            this.$router.push({
              path: this.sp ? '/' + this.sp : '/home',
            })
          })
        })
      })
    },
    // 处理登陆前执行回调路径跳转
    handleUrl() {
      return new Promise((resolve, reject) => {
        // 1.判断url是否有redirect参数
        const redirectUrl = this.$route.query.redirect
        // 2.如果有，则存储本地存储中
        if (redirectUrl) {
          localStorage.setItem('redirectUrl', decodeURIComponent(redirectUrl))
          // ZWJSBridge.setLocalStorage({
          //   key: 'redirectUrl',
          //   value: redirectUrl,
          // }).then((result) => {
          //   console.log('redirectUrl', result)
          //   Toast('存储redirectUrl成功')
          //   common.DialogStr(redirectUrl)
          //   resolve(result) // 成功时解析 promise
          // }).catch((error) => {
          //   Toast('存储redirectUrl失败')
          //   console.log(error)
          //   reject(error) // 失败时拒绝 promise
          // })
          console.log('存储redirectUrl成功')
          resolve(redirectUrl) // 成功时解析 promise
        } else {
          console.log('无回调地址')
          reject(new Error('无效的登录链接')) // 当没有回调地址时拒绝 promise
        }
      })
    },
    // 处理登录后执行回调路径跳转
    handleRedirectUrl() {
      return new Promise((resolve, reject) => {
        // 1.获取本地存储的redirectUrl
        // ZWJSBridge.getLocalStorage({
        //   key: 'redirectUrl',
        // }).then((result) => {
        //   console.log('redirectUrl', result)
        //   // 2.如果有，则跳转
        //   if (result) {
        //     Toast('跳转到' + result)
        //     this.$router.push({
        //       path: result,
        //     })
        //     resolve(result) // 成功时解析 promise
        //   } else {
        //     console.log('无回调地址')
        //     reject(new Error('无效的登录链接')) // 当没有回调地址时拒绝 promise
        //   }
        const redirectUrl = localStorage.getItem('redirectUrl')
        console.log('redirectUrl', redirectUrl)
        if (redirectUrl) {
          // Toast('跳转到' + redirectUrl)
          this.clearRedirectUrl()
          this.$router.push({
            path: redirectUrl,
          })
          resolve(redirectUrl) // 成功时解析 promise
        } else {
          console.log('无回调地址')
          this.$router.push({
            path: this.sp ? '/' + this.sp : '/home',
          })
          reject(new Error('无效的登录链接')) // 当没有回调地址时拒绝 promise
        }
        reject(new Error('无效的登录链接')) // 当没有回调地址时拒绝 promise

      })
    },
    //清除本地存储的redirectUrl
    clearRedirectUrl() {
      // ZWJSBridge.removeLocalStorage({
      //   key: 'redirectUrl',
      // }).then((result) => {
      //   console.log('clearRedirectUrl', result)
      // }).catch((error) => {
      //   // Toast('清除redirectUrl失败')
      //   console.log(error)
      // })
      localStorage.removeItem('redirectUrl')
    },
  },
}
</script>

<style scoped lang="less">
.sso-container {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e4ecf7 100%);
}

.loading-content {
  text-align: center;
  animation: fadeIn 0.5s ease;
}

.loading-text {
  color: #2c3e50;
  font-size: @font-size-base;
  margin-top: 20px;
  font-weight: 500;
}

.loading-spinner {
  position: relative;
  width: 50px;
  height: 50px;
  margin: 0 auto;
}

.circular {
  animation: rotate 2s linear infinite;
  height: 100%;
  transform-origin: center center;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
}

.path {
  stroke-dasharray: 1, 200;
  stroke-dashoffset: 0;
  animation: dash 1.5s ease-in-out infinite;
  stroke-linecap: round;
  stroke: #409eff;
  stroke-width: 4;
}

.loading-dots {
  margin-top: 15px;

  span {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: #409eff;
    margin: 0 3px;
    opacity: 0.6;

    &:nth-child(1) {
      animation: dot 1s infinite 0s;
    }

    &:nth-child(2) {
      animation: dot 1s infinite 0.2s;
    }

    &:nth-child(3) {
      animation: dot 1s infinite 0.4s;
    }
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
}

@keyframes dot {
  0%, 100% {
    opacity: 0.6;
    transform: scale(1);
  }
  50% {
    opacity: 1;
    transform: scale(1.2);
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
</style>
